/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-02 11:23:27
 * @Module: 搜索模态框
 */
 <template>
  <div class="searchDialog-box">

    <el-dialog :visible.sync="searchDialogVisible"
               width="100%"
               fullscreen
               :show-close="false"
               :title="null"
               center>
      <div class="searchDialog-center">
        <div class="searchDialog-center-header">
          <img src="@/static/img/laboratory/blacklogo.png"
               alt="logo"
               class="logo">
          <i class="el-icon-close hover"
             @click="searchDialogVisible=false"></i>
        </div>
        <div>
          <el-input v-model="keyword"
                    class="searchDialog-center-input"
                    placeholder="请输入关键词、用户"
                    @keyup.enter.native="searchFn"></el-input>
        </div>
        <div class="searchDialog-bottom">
          <div class="searchDialog-bottom-title">

            <span style="margin-right:5px;">搜索历史 </span>
            <i class="el-icon-delete hover"
               style="color:red;"
               @click="remove"></i>
          </div>
          <div class="searchDialog-bottom-box">
            <div class="searchDialog-bottom-item hover"
                 v-for="(item,index) in historyList"
                 :key="index"
                 @click="historyClick(item)">{{item}}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      keyword: '',
      historyList: []
    };
  },
  mounted () {
    this.historyList = this.get_searchHistory()
  },
  methods: {
    historyClick (keyword) {
      this.$router.push({
        path: '/homepage/seachList',
        query: {
          keyword
        }
      })
      this.searchDialogVisible = false
    },
    searchFn () {
      this.set_searchHistory(this.keyword);
      this.$router.push({
        path: '/homepage/seachList',
        query: {
          keyword: this.keyword
        }
      })
      this.searchDialogVisible = false
    },
    switchArry (res) {
      if (res)
        return res.split(',');
      else
        return []
    },
    get_searchHistory () {
      let lishisousuo = sessionStorage.getItem("searchHistory");
      if (lishisousuo) return this.switchArry(lishisousuo);
      else return [];
    },
    set_searchHistory (res) {
      let list = this.get_searchHistory();
      if (list.indexOf(res) < 0) list.unshift(res);
      sessionStorage.setItem("searchHistory", list.slice(0, 10).join(','));
    },
    remove () {
      sessionStorage.removeItem("searchHistory");
      this.historyList = [];
    }
  },
  computed: {
    searchDialogVisible: {
      get: function () {
        return this.$store.state.layouts.searchDialogVisible
      },
      set: function (res) {
        this.$store.commit('layouts/changeSearchDialogVisible', res)
      }
    }
  }
};
 </script>
 <style lang='scss' scoped>
.searchDialog-box /deep/ .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
.searchDialog-center {
  width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    img {
      width: 156px;
    }
    i {
      font-size: 24px;
    }
  }
  &-input {
    margin-top: 161px !important;
    width: 800px;
    height: 80px;
    ::placeholder {
      color: #999999;
      line-height: 56px;
      font-size: 40px;
    }
  }
  &-input /deep/ .el-input__inner {
    padding: 0px;
    background: rgba(0, 0, 0, 0);
    border: none;
    height: 76px;
    font-size: 40px;
    border-bottom: 1px solid #eee;
  }
}
.searchDialog-bottom {
  margin-top: 50px;
  width: 800px;
  &-title {
    font-size: 20px;
    color: #333333;
  }
  &-box {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: 106px;
    line-height: 36px;
    background: #eeeeee;
    border-radius: 2px;
    margin-top: 21px;
    text-align: center;
    margin-right: 16px;
  }
}
</style>