/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-29 14:59:03
 * @Module: 首页
 */
 <template>
  <div class="page" v-if="list.length">
    <el-container class="page-content">

      <el-main width="884px" class="page-content-l" style="padding:0px;min-height:400px">
        <item v-for="(item,index) in list" :key="item.uuid" :data="item" :itemindex="index" :showCommentId="showCommentId" @footer-click="onclick" @header-click="header_click" />
        <div class="homepage-pagination">
          <el-pagination background hide-on-single-page layout="prev, pager, next,jumper" :current-page="pagination.page" @current-change="currentChange" :page-size="pagination.pageSize" :total="pagination.total">
          </el-pagination>
        </div>
      </el-main>
      <el-aside width="340px" style="height:1245px" class="page-content-r" id="homepageAside">
        <seach-btn />
        <loginmodule />
        <assure />
        <!-- <carousel :class="{is_top:isFixed}" /> -->
        <attention />
      </el-aside>
    </el-container>
    <search-dialog />
    <transpond-dialog :visible="transpondDialogVisible" :data="selectData" @close="transpond_dialog_change" />
    <el-backtop :right="100">
      <div class="backtopStyle">
        <div class="el-icon-caret-top" />
        <div class="el-icon-caret-bottom" @click.stop="toBottom" />
      </div>
    </el-backtop>
    <praise v-if="showPraise" />
  </div>
</template>
 <script>

import searchDialog from "@/components/searchDialog"
import loginmodule from "@/views/login/loginmodule"
import attention from "@/views/homepage/rightmodule/attention"
// import carousel from "@/views/homepage/rightmodule/carousel"
import assure from "@/views/homepage/rightmodule/assure"
import seachBtn from "@/views/homepage/rightmodule/seachBtn"
import momentListMixins from "@/views/homepage/momentListMixins"
import praise from "@/views/homepage/module/praise"
import { Loading } from 'element-ui';
export default {
  components: {
    searchDialog,
    loginmodule,
    attention,
    // carousel,
    seachBtn,
    praise,
    assure
  },
  mixins: [momentListMixins],
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 20,
        page: null
      },
      isFixed: false,
      list: []
    };
  },
  created () {
    const page = +sessionStorage.getItem("page")
    if (page) {
      this.pagination.page = page
      this._get_list(page)
    } else {
      this._get_list()
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener("scroll", () => {
        this.handleScroll();
      });
    });
  },
  methods: {
    async _get_list (page = 1) {
      let loadingInstance = Loading.service();
      const { status, info, data, meta } = await this.$api.momentListApi({ page })
      this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        sessionStorage.setItem("page", meta.pagination.current_page);
      } else {
        this.$message.error(info)
      }

    },
    handleScroll () {
      if (!document.getElementById("homepageAside")) return
      if (this.$isElementNotInViewport(document.getElementById("homepageAside"))) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    toBottom () {
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      window.scrollTo({
        top: scrollHeight,
        behavior: "smooth"
      });
    }
  },
  computed: {
    showPraise () {
      return this.$store.state.homepage.showPraise
    }
  }, //计算属性
  beforeDestroy () {
  },
};
 </script>
 <style lang='scss' scoped>
.page {
  background: #f7f7f7;
  min-height: 100vh;
  border: solid 1px #f7f7f7;
}
.page-content {
  width: 1240px;
  margin: 0 auto;
  margin-top: 80px;
  &-l {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  }
  &-r {
    margin-left: 16px;
  }
}
.homepage-pagination {
  display: flex;
  justify-content: center;
  background: #fff;
  margin-top: -10px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.is_top {
  position: fixed;
  top: 60px;
  // bottom: 0px;
}
.backtopStyle {
  height: 40px;
  width: 40px;
  background-color: #f2f5f6;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    width: 100%;
    text-align: center;
    transition: all 0.5s;
    color: #bbb;
    &:hover {
      background: #fff;
      color: #df2a29;
    }
  }
}
</style>